import { BrowserModule } from '@angular/platform-browser'
import { NgModule } from '@angular/core'
import { AppRoutingModule } from './app.routing'
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'

// Uncomment code below when app is deployed
// import { enableProdMode } from '@angular/core';

// enableProdMode();

// Custom Modules
import { UserRootModule } from './user-module/user-root.module'
import { AdminRootModule } from './admin-module/admin-root/admin-root.module'

import { JwtInterceptor, ErrorInterceptor } from './_helpers';
import { AppComponent } from './app.component';

@NgModule({
	declarations: [
		AppComponent,
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		HttpClientModule,
		UserRootModule,
		AdminRootModule,
		FormsModule,
		ReactiveFormsModule,
	],
	providers: [	
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
