<header>
	<input type="checkbox" id="header-main--trigger" class="header-checkbox" />
	<label for="header-main--trigger">
		<span class="header-main--trigger_icon">
			<i class="header-main--cross_hatch"></i>
		</span>
	</label>

	<nav>
		<ul>
			<li><a routerLink="/admin">Dashboard</a></li>
			<li><a routerLink="/admin/pending-runs">Pending Runs</a></li>

			<li class="dropdown" (click)="toggleDropdown()">
				<button>{{ auth.currentUserValue?.first_name + ' ' + auth.currentUserValue?.last_name }}</button>
				<i class="dropdown-arrow"></i>
				<ul class="dropdown-list" *ngIf="showDropdown">
					<li><a (click)="logout()">Logout</a></li>
				</ul>
			</li>
		</ul>
	</nav>
</header>