import { Component, OnInit } from '@angular/core';
import { AuthenticationService, UserService } from '../../../_services'
import { Referral } from '../../../_models'

@Component({
  selector: 'app-pending-bookings',
  templateUrl: './pending-bookings.component.html',
  styleUrls: ['./pending-bookings.component.css'],
})

export class PendingBookingsComponent implements OnInit {
	referrals: [Referral]

	constructor(private auth: AuthenticationService, private userService: UserService) { }

	ngOnInit(): void {
		this.userService.pendingBookings(this.auth.currentUserValue.id).subscribe( referrals => {
			console.log(referrals)
			this.referrals = referrals
		})
	}

}
