<div class="app">
	<user-header></user-header>

	<div class="selected-salesman">
		<div class="address" *ngIf="user?.street && user?.city && user?.state && user?.zip">
			<p *ngIf="user?.street">{{ user?.street }}</p>
			<p *ngIf="user?.city">{{ user?.city + ', ' + user?.state + ' ' + user?.zip }}</p>
		</div>

		<div class="edit-address">
			<p *ngIf="!user?.street || !user?.city || !user?.state || !user?.zip">If you don't have a complete mailing address we can't mail you your checks. Add one by clicking the button below.</p>
			<button class="btn btn-primary" (click)="toggleAddressForm()">Change mailing address</button>

			<form *ngIf="changeAddress" [formGroup]="addressForm" (ngSubmit)="saveAddress(addressForm.value)">
				<div class="field">
					<input type="text" formControlName="street" placeholder="Street" (change)="changeStreet($event.target.value)">
					<p class="form-error" *ngIf="addressForm.get('street').touched && addressForm.get('street').hasError('required')">Field is required</p>
				</div>

				<div class="field">
					<input type="text" formControlName="city" placeholder="City" (change)="changeCity($event.target.value)">
					<p class="form-error" *ngIf="addressForm.get('city').touched && addressForm.get('city').hasError('required')">Field is required</p>
				</div>

				<div class="field">
					<input type="text" formControlName="state" placeholder="State" (change)="changeState($event.target.value)">
					<p class="form-error" *ngIf="addressForm.get('state').touched && addressForm.get('state').hasError('required')">Field is required</p>
				</div>

				<div class="field">
					<input type="text" formControlName="zip" placeholder="Zip code" (change)="changeZip($event.target.value)">
					<p class="form-error" *ngIf="addressForm.get('zip').touched && addressForm.get('zip').hasError('required')">Field is required</p>
				</div>

				<div class="field submit">
					<button class="btn btn-primary">Save</button>
					<img *ngIf="loading" class="pl-2" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
				</div>

				<p class="form-error" *ngIf="formError && !loading && submitted">{{ formError }}</p>
			</form>
		</div>
	</div>

	<div class="container">
		<div>

			<div class="account-data">
				<div>
					<p>{{ user?.recruit_count }}</p>
					<p>{{ user?.recruit_count | i18nPlural: pluralMapping['salesman'] }} recruited</p>
				</div>

				<div>
					<p>{{ user?.account_total | currency:'USD':'symbol' }}</p>
					<p>Account total</p>
				</div>
			</div>
		</div>

		<div class="transaction-table" *ngIf="user?.transactions.length">
			<div class="transaction-header">
				<div class="transaction-date">Date</div>
				<div class="transaction-description">Description</div>
				<div class="transaction-run-total">Sale Total</div>
				<div class="transaction-amount">Amount</div>
				<div class="transaction-balance">Balance</div>
			</div>

			<div class="transactions">
				<div class="transaction" *ngFor="let transaction of user?.transactions">
					<div class="transaction-date">{{ transaction.createdAt | date }}</div>
					<div class="transaction-description">{{ transaction.description }}</div>
					<div class="transaction-run-total">{{ transaction.sale_amount  | currency:'USD':'symbol' }}</div>
					<div class="transaction-amount" [ngClass]="{'credit': transaction.amount > 0, 'debit': transaction.amount < 0}">{{ transaction.amount  | currency:'USD':'symbol' }}</div>
					<div class="transaction-balance">{{ transaction.balance | currency:'USD':'symbol' }}</div>
				</div>
			</div>
		</div>
	</div>
</div>