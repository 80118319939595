import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'

import { User, Config, UserProfile, PotentialRecruit, RecruitDetails, Referral, ResponseError } from '../_models'


@Injectable({ providedIn: 'root' })
export class ReferralService {
    constructor(private http: HttpClient) { }

    list(conditions: {}) {
        return this.http.get<[Referral]>(`${Config.apiUrl}/referral`, { params: conditions })
    }

    get(id: number) {
        return this.http.get<Referral>(`${Config.apiUrl}/referral/${id}`)
    }

    create(referral: Referral) {
        return this.http.post<Referral>(`${Config.apiUrl}/referral`, referral)
    }

    update(referral: Referral) {
        return this.http.put<Referral>(`${Config.apiUrl}/referral/${referral.id}`, referral)
    }
}