import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'

import { PhonePipe } from './';

@NgModule({
  imports: [
    // dep modules
  ],
  declarations: [ 
    PhonePipe
  ],
  exports: [
    PhonePipe
  ]
})

export class PipesModule {}