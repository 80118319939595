import { Transaction } from './transaction'

export class UserProfile {
	id: number
	first_name: string
	last_name: string
	primary_phone: string
	secondary_phone: string
	password: string
	updatedAt: string
	createdAt: string
	recruits: [Recruit]
}

interface Recruit {
	id: number
	first_name: string
	last_name: string
	recruits: [SubRecruit],
	transactions?: [Transaction],
	recruiter_sum?: number
}

interface SubRecruit {
	id: number
	first_name: string
}