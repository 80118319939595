import { Component, OnInit } from '@angular/core'
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms'
import { Router, ActivatedRoute } from '@angular/router'
import { first } from 'rxjs/operators'

import { CommonValidators } from '../../_validators/common.validator'

import { AuthenticationService } from '../../_services/authentication.service'

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
	private pageTitle: String = 'Login'
	
	public loginForm: FormGroup
	public serverError: String
	public loading: boolean = false

	returnUrl: string
	params

	constructor(
		private _fb:FormBuilder, 
		private router:Router, 
		private route: ActivatedRoute, 
		private auth: AuthenticationService
	) { 
		// redirect if logged in
		if (this.auth.currentUserValue) { 
            this.router.navigate(['/user'])
        }
	}

	ngOnInit() {
		this.createLoginForm()
		this.returnUrl = this.route.snapshot.queryParams['redirect'] || '/user'
	}

	createLoginForm() {
		this.loginForm = this._fb.group({
			email: ['', [Validators.required, Validators.email, CommonValidators.whiteSpace]],
			password: ['', [Validators.required, CommonValidators.whiteSpace]]
		})
	}

	get f() { return this.loginForm.controls }

	performLogin() {
		console.log('logging in')
		if(this.loginForm.invalid) {
			return
		}

		this.loading = true
		this.auth.login(this.f.email.value, this.f.password.value)
			.pipe(first())
            .subscribe(
                data => {
                	console.log('got server response')
                	if(this.auth.currentUserValue.role == 'Admin') {
                		this.router.navigate(['/admin'])
                	} else {
                		this.router.navigate([this.returnUrl])	
                	}
                },
                error => {
                	console.log(error)
                    this.serverError = error
                    this.loading = false
                })
	}
}
