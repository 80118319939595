import { NgModule, ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

//Models
import { Role } from './../../_models/role'

//Services
import { AuthGuard } from '../../_guard/auth.guard'

//Components
import { DashboardComponent } from './dashboard/dashboard.component'
import { SalesmanDetailsComponent } from './salesman-details/salesman-details.component'
import { PendingRunsComponent } from './pending-runs/pending-runs.component'

const adminRoutes: Routes = [
	{
		path: 'admin',
		component: DashboardComponent,
		canActivate: [AuthGuard],
		data: { roles: [Role.Admin] }
	},
	{
		path: 'admin/dashboard',
		component: DashboardComponent,
		canActivate: [AuthGuard],
		data: { roles: [Role.Admin] }
	},
	{
		path: 'admin/salesman/:id',
		component: SalesmanDetailsComponent,
		canActivate: [AuthGuard],
		data: { roles: [Role.Admin] }
	},
	{
		path: 'admin/pending-runs',
		component: PendingRunsComponent,
		canActivate: [AuthGuard],
		data: { rols: [Role.Admin] }
	}
]

@NgModule({
	imports: [
		RouterModule.forRoot(adminRoutes)
	],
	exports: [
		RouterModule
	]
})

export class AdminRootRoutingModule{}