import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { AdminRootRoutingModule } from './admin-root.routing'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'


import { PipesModule } from './../../_pipes/pipes.module'

//Services
import { AuthGuard } from './../../_guard/auth.guard';


// Components
import { DashboardComponent } from './dashboard/dashboard.component';
import { AdminHeaderComponent } from './admin-header/admin-header.component';
import { SalesmanDetailsComponent } from './salesman-details/salesman-details.component';
import { SearchSalesmenComponent } from './search-salesmen/search-salesmen.component';
import { PendingRunsComponent } from './pending-runs/pending-runs.component'

@NgModule({
	declarations: [
		DashboardComponent,
		AdminHeaderComponent,
		SalesmanDetailsComponent,
		SearchSalesmenComponent,
		PendingRunsComponent
	],
	imports: [
		CommonModule,
		AdminRootRoutingModule,
		FormsModule,
		ReactiveFormsModule,
		PipesModule
	],
	providers: [
		AuthGuard
	]
})
export class AdminRootModule { }
