<div class="app">
	<user-header></user-header>

	<div class="container">
		<h1 *ngIf="referrals?.length">Pending Referrals</h1>	

		<div *ngIf="!referrals?.length">
			<p>You have no valid referrals</p>
		</div>
		<div class="referral-table" *ngIf="referrals?.length">

			<div class="referral-header">
				<div class="referral-name">Name</div>
				<div class="referral-phone">Phone</div>
				<div class="referral-booked">Expires</div>
				<div class="referral-complete"></div>
			</div>

			<div class="referrals">
				<div class="referral" *ngFor="let referral of referrals">
					<div class="referral-name"><p>{{ referral.name }}</p></div>
					<div class="referral-phone"><p>***-***-{{ referral.primary_phone | slice:7:11 }}</p></div>
					<div class="referral-booked"><p>{{ referral.expires | date }}</p></div>
					<div class="referral-complete">
						<span *ngIf="referral.expires < now" class="expired">Expired</span>
						<span *ngIf="referral.expires > now" class="pending">Pending</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
