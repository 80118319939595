<div class="app">
	<user-header></user-header>

	<div class="top-content">
		<p class="recruit-salesman" (click)="showRecruitForm()">Recruit Salesman</p>

		<form [formGroup]="recruitForm" *ngIf="showForm" (ngSubmit)="performReferal(recruitForm.value)" novalidate>
			<div class="field">
				<input id="tel" type="text" formControlName="phone" value="" placeholder="phone" 
					phoneMask 
					[preValue]="recruitForm.value.phone"
					[phoneControl]="recruitForm.controls['phone']" maxlength="17"
					autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false">
				<p class="form-error" *ngIf="recruitForm.get('phone').touched && recruitForm.get('phone').hasError('phone')">Invalid phone number</p>
			</div>

			<div class="field submit">
				<button [class.disabled]="!recruitForm.valid" class="btn btn-primary">Send Referal</button>
				<img *ngIf="loading" class="pl-2" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
			</div>

			<div *ngIf="formErrors"> 
				<p class="form-error" *ngFor="let error of formErrors">{{ error }}</p>
			</div>

			<p *ngIf="submitted && success && !loading" class="form-success">Refferal successfully sent</p>
		</form>
	</div>


	<div class="container">

		<div class="recruits">
			<a class="recruit" routerLink="/user/recruit/{{ recruit.id }}" *ngFor="let recruit of user?.recruits">
				<img src="assets/icon-blue-button.png" srcset="assets/icon-blue-button.png 2x" alt="blue button" />
				<p>{{ recruit.first_name }}</p>
				<p>{{ recruit.last_name }}</p>
				<p>{{ recruit.recruiter_sum | currency:'USD' }}</p>
				<div class="sub-recruits">
					<div class="sub-recruit" *ngFor="let subRecruit of recruit.recruits">
						<img src="assets/icon-yellow-button.png" srcset="assets/icon-yellow-button.png 2x" alt="yellow button"/>
						<p>{{ subRecruit.first_name }}</p>
					</div>
				</div>
			</a>
		</div>
	</div>
</div>