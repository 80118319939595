import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'phone'
})

export class PhonePipe implements PipeTransform {
    transform(val, args) {

        // val = val.charAt(0) != 0 ? '0' + val : '' + val;
        let newStr = '';

        console.log(val, args)
        switch(args) {

        	//US phone number
        	default:
        		let match = val.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/)

        		if(match) {
        			return '+' + match[1] + '-(' + match[2] + ')-' + match[3] + '-' + match[4]
        		}
        	break;
        }

        return val
    }
}