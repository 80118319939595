import { Role } from './role'
import { Transaction } from './transaction'
import { RecruitDetails } from './recruit-details'

export class User {
	id: number
	first_name: string
	last_name: string
	email: string
	primary_phone: string
	secondary_phone: string
	password: string
	street: string
	city: string
	state: string
	zip: string
	role: Role
	filled_tax_form: boolean
	token?: string
	transactions?: [Transaction]
	recruits?: [RecruitDetails]
	recruit_count?: number
	account_total?: number
	yearly_total?: number
}