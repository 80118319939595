<div class="app">
	<user-header></user-header>

	<div class="selected-salesman">
		<img src="assets/icon-blue-button.png" srcset="assets/icon-blue-button.png 2x" alt="blue button">
		<p>{{ user?.first_name + ' ' + user?.last_name }}</p>
	</div>

	<div class="container">
		<div class="recruits">
			<div class="recruit" *ngFor="let recruit of user?.recruits">
				<img src="assets/icon-yellow-button.png" srcset="assets/icon-yellow-button.png 2x" alt="yellow button" />
				<p>{{ recruit.first_name }}</p>
				<p>{{ recruit.recruiter_sum | currency:'USD' }}</p>
			</div>
		</div>
	</div>
</div>