import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from './../../../_services'
import { Router } from '@angular/router'

import { Config } from '../../../_models'


@Component({
  selector: 'user-header',
  templateUrl: './user-header.component.html',
  styleUrls: ['./user-header.component.css']
})
export class UserHeaderComponent implements OnInit {
	showYearlyTotalAlert: boolean
	showDropdown: boolean = false
	config = Config

	constructor(public auth: AuthenticationService, private router: Router) { }

	ngOnInit() {
		this.showYearlyTotalAlert = this.auth.currentUserValue.yearly_total >= Config.taxFormAlertCap && !this.auth.currentUserValue.filled_tax_form ? true : false
	}

	toggleDropdown() {
		this.showDropdown = !this.showDropdown
	}

	logout() {
		this.auth.logout()
		this.router.navigate(['/'])
	}

}
