<header>
	<input type="checkbox" id="header-main--trigger" class="header-checkbox" />
	<label for="header-main--trigger">
		<span class="header-main--trigger_icon">
			<i class="header-main--cross_hatch"></i>
		</span>
	</label>

	<nav>
		<ul>
			<li><a routerLink="/user/pending-bookings">Pending Bookings</a></li>
			<li><a routerLink="/user">My Recruits</a></li>
			<li><a routerLink="/user/refer">Refer client</a></li>
			<li><a routerLink="/user/referrals">My Referrals</a></li>
			<li class="dropdown" (click)="toggleDropdown()">
				<button>{{ auth.currentUserValue?.first_name + ' ' + auth.currentUserValue?.last_name }}</button>
				<i class="dropdown-arrow"></i>
				<ul class="dropdown-list" *ngIf="showDropdown">
					<li><a routerLink="/user/account">Account</a></li>
					<li><a (click)="logout()">Logout</a></li>
				</ul>
			</li>
		</ul>
	</nav>
</header>

<div class="notice" *ngIf="showYearlyTotalAlert">
	<p>
		The amount you have made this year has exceeded ${{ config.taxFormAlertCap }}. You will not be able to cash out until you fill 
		out a 1099 form and send it in to the office after you reach ${{ config.yearlyTotalCap }}.
	</p>
</div>