import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { I18nPluralPipe } from '@angular/common'
import { FormGroup, FormArray, FormBuilder, Validators, FormControl } from '@angular/forms'
import { CommonValidators } from '../../../_validators/common.validator'
import { first } from 'rxjs/operators'

import { UserService, AdminService } from '../../../_services'
import { User, Transaction } from '../../../_models'

@Component({
  selector: 'salesman-details',
  templateUrl: './salesman-details.component.html',
  styleUrls: ['./salesman-details.component.css']
})
export class SalesmanDetailsComponent implements OnInit {
	paymentForm: FormGroup
	loading: boolean = false
	success: boolean = false
	submitted: boolean = false

	salesman: User
	pluralMapping = {
		'salesman': {
			'=0': 'Salesmen',
			'=1': 'Salesman',
			'other': 'Salesmen'
		}
	}

	constructor(
		private route: ActivatedRoute,
		private userService: UserService,
		private adminService: AdminService,
		private fb: FormBuilder
	) { 
		this.route.params.subscribe( params => {
			this.userService.account({id: params.id }).subscribe( user => {
				this.salesman = user
			})
		})
	}

	get f() {
		return this.paymentForm.controls
	}

	ngOnInit() {
		this.buildForm()
	}

	buildForm() {
		this.paymentForm = this.fb.group({
			'amount': ['', [Validators.required, CommonValidators.decimal]],
			'check_number': ['', [Validators.required]]
		})
	}


	paySalesman() {
		if(this.f.amount.value > this.salesman.account_total) {
			this.f.amount.setErrors({ 'lessThan': true })
		}

		console.log(this.success)
		if(this.paymentForm.invalid || this.f.amount.value <= 0) {
			this.success = false
			return 
		}

		


		let transaction: Transaction = {
			'description': 'Payment: Check #' + this.f.check_number.value,
			'sale_amount': 0,
			'amount': this.f.amount.value,
			'user_id': this.salesman.id
		}

		this.loading = true
		this.submitted = true
		this.adminService.createTransaction(transaction)
			.pipe(first())
			.subscribe( 
				data => {
					this.loading = false
					this.success = true
					this.salesman.transactions.unshift(data)
				},
				error => {
					this.loading = false
					this.success = false
				}
			)

	}

	updateTaxFormStatus(status) {
		this.salesman.filled_tax_form = status
		this.userService.update(this.salesman).subscribe( 
			user => {
				console.log(user)
			},
			error => {
				console.log(error)
			}
		)
	}

}
