import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'

import { User, Config, UserProfile, PotentialRecruit, RecruitDetails, Referral, ResponseError } from '../_models'


@Injectable({ providedIn: 'root' })
export class UserService {
    constructor(private http: HttpClient) { }

    list(conditions: {}) {
        return this.http.get<[User]>(`${Config.apiUrl}/user`, { params: conditions })
    }

    get(id: number) {
        return this.http.get<User>(`${Config.apiUrl}/user/${id}`)
    }

    create(user: User) {
        return this.http.post<User>(`${Config.apiUrl}/user`, user)
    }

    update(user: User) {
        return this.http.put<User>(`${Config.apiUrl}/user/${user.id}`, user)
    }

    account(conditions?: {}) {
        return this.http.get<User>(`${Config.apiUrl}/user/account`, { params: conditions })
    }

    userProfile() {
        return this.http.get<UserProfile>(`${Config.apiUrl}/user/profile`)
    }

    potentialRecruit(recruit: PotentialRecruit) {
        return this.http.post<any>(`${Config.apiUrl}/potential-recruit`, recruit)
    }

    recruitDetails(id: number) {
        return this.http.get<RecruitDetails>(`${Config.apiUrl}/user/recruit-details/${id}`)
    }

    referClient(client: Referral) {
        return this.http.post<any>(`${Config.apiUrl}/referral`, client)
    }

    pendingBookings(id: number) {
        return this.http.get<[Referral]>(`${Config.apiUrl}/user/pending-bookings/${id}`)
    }

    activeReferrals() {
        return this.http.get<[Referral]>(`${Config.apiUrl}/user/referrals`)
    }
}