import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../_services'
import { User, SalesmanSearch } from '../../../_models'

@Component({
  selector: 'admin-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
	salesmen: [User]
	defaultList: [User]

	constructor(private userService: UserService) { }

	ngOnInit() {
		this.userService.list({ role: 'User' }).subscribe( salesmen => {
			this.defaultList = salesmen
			this.setSalesmen({ salesmen: salesmen, searchString: '' })
		})
	}

	setSalesmen(data: SalesmanSearch) {

		if(data.searchString.length < 3) {
			this.salesmen = this.defaultList
		} else {
			this.salesmen = data.salesmen	
		}
	}

}
