import { Component, OnInit } from '@angular/core'
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms'
import { HttpErrorResponse } from '@angular/common/http'
import { I18nPluralPipe } from '@angular/common'


import { AuthenticationService, UserService } from '../../../_services'
import { User } from '../../../_models'


@Component({
  selector: 'account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css']
})

export class AccountComponent implements OnInit {
	user: User
	addressForm: FormGroup
	pluralMapping = {
		'salesman': {
			'=0': 'Salesmen',
			'=1': 'Salesman',
			'other': 'Salesmen'
		}
	}
	changeAddress: boolean = false
	loading: boolean = false
	success: boolean = false
	submitted: boolean = false
	formError: string


	constructor(
		private auth: AuthenticationService, 
		private _fb: FormBuilder,
		private userService: UserService
	) { }

	ngOnInit() {
		this.userService.account().subscribe( user => {
			this.user = user

			this.createAddressForm()
		})
	}

	createAddressForm() {
		this.addressForm = this._fb.group({
			'street': [this.user.street, Validators.required],
			'city': [this.user.city, Validators.required],
			'state': [this.user.state, Validators.required],
			'zip': [this.user.zip, Validators.required]
		})
	}

	toggleAddressForm() {
		this.changeAddress = !this.changeAddress
	}

	changeStreet(street) {
		this.user.street = street
	}

	changeCity(city) {
		this.user.city = city
	}

	changeState(state) {
		this.user.state = state
	}

	changeZip(zip) {
		this.user.zip = zip
	}

	saveAddress() {
		this.loading = true
		this.submitted = true
		this.formError = null


		this.userService.update(this.user).subscribe( res => {
			this.changeAddress = false
			this.loading = false
			this.success = true
		}, (error) => {
			this.formError = error
			this.loading = false
			this.success = false
		})
	}

}
