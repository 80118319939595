import { Component, OnInit, Output, EventEmitter } from '@angular/core'
import { FormGroup, FormArray, FormBuilder, Validators, FormControl } from '@angular/forms'

import { Role } from '../../../_models'
import { UserService } from '../../../_services'
import { Subject, Observable } from 'rxjs'
import { debounceTime, distinctUntilChanged } from 'rxjs/operators'

@Component({
  selector: 'search-salesmen',
  templateUrl: './search-salesmen.component.html',
  styleUrls: ['./search-salesmen.component.css']
})
export class SearchSalesmenComponent implements OnInit {
	@Output() searchUpdated = new EventEmitter

	private searchSubject: Subject<any> = new Subject()
	term = new FormControl()

	constructor(private userService: UserService) { }

	ngOnInit() {
		this.term.valueChanges.pipe(debounceTime(400)).pipe(distinctUntilChanged()).subscribe( string => {
			this.search(string)
		})
	}


	search(searchString: string) {
		if(searchString.length < 3) {
			this.searchUpdated.emit({ salesmen: [], searchString: searchString })
			return
		}

		this.userService.list({ search: true, searchString: searchString, role: Role.User }).subscribe( userList => {
			this.searchUpdated.emit({ salesmen: userList, searchString: searchString })
		})
	}
}
