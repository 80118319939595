import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'

import { Transaction, Config, UserProfile, PotentialRecruit, RecruitDetails, Referral, ResponseError } from '../_models'


@Injectable({ providedIn: 'root' })
export class AdminService {
    constructor(private http: HttpClient) { }

	createTransaction(transaction: Transaction) {
		return this.http.post<Transaction>(`${Config.apiUrl}/transaction`, transaction)
	}
}