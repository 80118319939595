import { Component, OnInit } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms'
// import { HttpErrorResponse } from '@angular/common/http'
import { first } from 'rxjs/operators'

import { AuthenticationService, UserService } from '../../../_services'
import { CommonValidators } from '../../../_validators/common.validator'

import { UserProfile, PotentialRecruit } from '../../../_models'

@Component({
  selector: 'user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css'],
  providers: [UserService]
})
export class UserComponent implements OnInit {
	recruitForm: FormGroup
	user: UserProfile
	showForm: boolean = false
	formErrors: Array<string>
	submitted: boolean = false
	loading: boolean = false
	success: boolean = false

	constructor(private auth: AuthenticationService, private fb: FormBuilder, private userService: UserService) { }

	ngOnInit() {
		this.userService.userProfile().subscribe( user => {
			console.log(user)
			this.user = user

			for( let [i, recruit] of this.user.recruits.entries()) {
				this.user.recruits[i].recruiter_sum = recruit.transactions.map(a => a.amount).reduce(function(a, b) {
  				return Number(a) + Number(b);
  			})
			}
		}, (err) => {
			console.log(err)
		})

		this.createRecruitForm()
	}

	createRecruitForm() {
		this.recruitForm = this.fb.group({
			'phone': ['', [Validators.required, CommonValidators.phone]],
		})
	}

	showRecruitForm() {
		this.showForm = !this.showForm
	}

	performReferal(recruit: PotentialRecruit) {
		this.success = false
		this.submitted = true

		if(!this.recruitForm.valid) {
			return
		}

		recruit.userId = this.auth.currentUserValue.id
		recruit.phone = recruit.phone.replace(/\D/g, '')

		this.formErrors = []
		this.loading = true

		this.userService.potentialRecruit(recruit).subscribe( res => {

			this.success = true
			this.loading = false
			this.recruitForm.reset()

		}, (error) => {
			console.log(error)
			this.formErrors.push(error)
			this.loading = false
			this.submitted = false
			this.success = false
			
		})
	}

}
