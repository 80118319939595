import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { BehaviorSubject, Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import { User, Config } from '../_models/'

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<User>
    public currentUser: Observable<User>

    constructor(private http: HttpClient) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')))
        this.currentUser = this.currentUserSubject.asObservable()
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value
    }


    public setUser(user) {
        // login successful if there's a jwt token in the response
        if (user && user.token) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('currentUser', JSON.stringify(user))
            this.currentUserSubject.next(user)
        }

        return user
    }

    register(user) {
        return this.http.post<any>(`${Config.apiUrl}/register`, user)
            .pipe(map(user => {
                console.log(user)
                return this.setUser(user)
            }))
    }

    login(email: string, password: string) {
        console.log('sending login creds to server')
        return this.http.post<any>(`${Config.apiUrl}/login`, { email, password })
            .pipe(map(user => {
                console.log('setting returned user')
                return this.setUser(user)
            }))
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser')
        this.currentUserSubject.next(null)
    }
}