import { User } from './user'

export class Referral {
	id: number
	name: string
	primary_phone: string
	contact_preferance: string
	booked: boolean
	booked_date: string
	run_completed: boolean
	run_end_date: string
	expires: string
	cancelled: boolean
	userId: number
	user?: User
}