<div class="app">
	<admin-header></admin-header>

	<div class="container">
		<div class="referral-table">

			<div class="referral-header">
				<div class="referral-name">Name</div>
				<div class="referral-phone">Phone</div>
				<div class="referral-booked">Date Booked</div>
				<div class="referral-scheduled">Run Scheduled</div>
				<div class="referral-remove"></div>
			</div>

			<div class="referrals">
				<div class="referral" *ngFor="let referral of referrals">
					<div class="referral-name"><p>{{ referral.name }}</p></div>
					<div class="referral-phone"><p>{{ referral.primary_phone | phone }}</p></div>
					<div class="referral-booked"><p>{{ referral.booked_date | date }}</p></div>
					<div class="referral-scheduled"><p>{{ referral.run_end_date | date }}</p></div>
					<div class="referral-remove">
						<img src="assets/icon-remove.png" srcset="assets/icon-remove@2x.png 2x" title="Cancel run" alt="remove icon" (click)="cancelRun($event, referral)">
					</div>
				</div>
			</div>
		</div>
	</div>
</div>