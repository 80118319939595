import { Component, OnInit, OnDestroy } from '@angular/core'
import { ActivatedRoute } from '@angular/router'


import { AuthenticationService, UserService } from '../../../_services'
import { RecruitDetails } from '../../../_models'

@Component({
  selector: 'app-recruit-details',
  templateUrl: './recruit-details.component.html',
  styleUrls: ['./recruit-details.component.css']
})

export class RecruitDetailsComponent implements OnInit {
	user: RecruitDetails

	sub

	constructor(
		private auth: AuthenticationService,
		private route: ActivatedRoute, 
		private userService: UserService
	) { 
		this.sub = this.route.params.subscribe( params => {
			this.userService.recruitDetails(params['id']).subscribe( user => {
				console.log(user)
				this.user = user

				for( let [i, recruit] of this.user.recruits.entries()) {
					this.user.recruits[i].recruiter_sum = recruit.transactions.map(a => a.amount).reduce(function(a, b) {
	  				return a + b;
	  			})
				}
			})
		})
	}

	ngOnInit() {

	}

	ngOnDestroy() {
		this.sub.unsubscribe()
	}

}