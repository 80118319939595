import { Component, OnInit } from '@angular/core';
import { ReferralService } from '../../../_services'
import { Referral } from '../../../_models'

@Component({
  selector: 'app-pending-runs',
  templateUrl: './pending-runs.component.html',
  styleUrls: ['./pending-runs.component.css']
})
export class PendingRunsComponent implements OnInit {
	referrals: [Referral]

	constructor(private referralService: ReferralService) { 
		this.referralService.list({ booked: true, run_completed: false, cancelled: false }).subscribe( referrals => {
			this.referrals = referrals
		})
	}

	ngOnInit() {
	}

	cancelRun(event, referral: Referral) {
		let conf = confirm('Are you sure you want to cancel this run?')

		if(!conf) {
			return
		}

		referral.cancelled = true


		this.referralService.update(referral).subscribe( 
			referral => {
				this.removeRow(event.target)
			},
			error => {
				alert(error)
			}
		)
	}

	removeRow(element) {
		element.parentNode.parentNode.remove()
	}
}
