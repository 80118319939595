<div class="app">
	<admin-header></admin-header>


	<div class="container">
		<search-salesmen (searchUpdated)="setSalesmen($event)"></search-salesmen>

		<h2>Salesmen</h2>
		<ul>
			<li *ngFor="let salesman of salesmen" class="salesman">
				<a routerLink="salesman/{{ salesman.id }}">{{ salesman.first_name }} {{ salesman.last_name }}</a>
			</li>
		</ul>
	</div>
</div>
