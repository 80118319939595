import { FormControl } from '@angular/forms'

export class CommonValidators {
	static number(control: FormControl) {
		let NUMBER_REG = /^[1-9]{1}\d*$/
		
		return NUMBER_REG.test(control.value) || control.value == 0 ? null : { number: true }
	}

	static decimal(control: FormControl) {
		let DECIMAL_REG = /^([1-9]{1})?(\d+)?(\.[0-9]([0-9]{1})?)?$/

		return DECIMAL_REG.test(control.value) || control.value == 0 ? null : { decimal: true }
	}

	static whiteSpace(control: FormControl) {
		return /\S/.test(control.value) ? null : { whiteSpace: true }
	}

	static passwordMatches(parent: string) {
		let confirmControl: FormControl
		let passwordControl: FormControl

		return (control: FormControl) => {
			if(!control.parent) {
				return null
			}

			
			confirmControl = control
			passwordControl = confirmControl.parent.get(parent) as FormControl

			return  passwordControl.value === confirmControl.value ? null : { noMatch: true }
		}
	}

	static phone(control: FormControl) {
		let reg = /^\+\d{1} \(\d{3}\)\s\d{3}-\d{4}$/

		return reg.test(control.value) ? null : { phone: true };
	}

	static dateAfterToday(control: FormControl) {

		if(isNaN(Date.parse(control.value))) {
			return { invalidDate: true }
		}

		let today = new Date()
		let date = new Date(control.value)

		return date > today ? null : { invalidDate: true }
	}

	static dateTodayForward(control: FormControl) {
		if(isNaN(Date.parse(control.value))) {
			return { invalidDate: true }
		}

		let separatedDate = control.value.split(/[\/\-]/)
		let today = new Date()
		let date = new Date(separatedDate[0], separatedDate[1] - 1, separatedDate[2]) // -1 because january starts at 0

		today.setHours(0,0,0,0) // Set time to start of day to compare to selected date

		return date >= today ? null : { invalidDate: true }
	}

	static lessThan(amount: number) {

		return (control: FormControl) => {
			return control.value < amount ? null : { lessThan: true }
		}
	}
}