import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router'


import { AuthenticationService } from '../../../_services/authentication.service'

@Component({
  selector: 'admin-header',
  templateUrl: './admin-header.component.html',
  styleUrls: ['./admin-header.component.css']
})
export class AdminHeaderComponent implements OnInit {
	showDropdown: boolean = false

	constructor(public auth: AuthenticationService, private router: Router) { }

	ngOnInit() {
	}

	toggleDropdown() {
		this.showDropdown = !this.showDropdown
	}

	logout() {
		this.auth.logout()
		this.router.navigate(['/'])
	}
}
