import { Component, OnInit } from '@angular/core';
import { AuthenticationService, UserService } from '../../../_services'
import { Referral } from '../../../_models'

@Component({
  selector: 'user-refferals',
  templateUrl: './user-refferals.component.html',
  styleUrls: ['./user-refferals.component.css']
})
export class UserRefferalsComponent implements OnInit {
    public referrals: [Referral]
    public now: any = new Date().toISOString()

    constructor(private auth: AuthenticationService, private userService: UserService) { }

    ngOnInit(): void {
        this.userService.activeReferrals().subscribe( referrals => {
            this.referrals = referrals
        })
    }

}
