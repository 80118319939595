import { NgModule, ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

//Services
import { AuthGuard } from '../_guard/auth.guard'

//Components
import { LoginComponent } from './login/login.component'
import { RegisterComponent } from './register/register.component'
import { UserComponent } from './user-root/user/user.component'
import { AccountComponent } from './user-root/account/account.component'
import { ReferComponent } from './user-root/refer/refer.component'
import { RecruitDetailsComponent } from './user-root/recruit-details/recruit-details.component'
import { PendingBookingsComponent } from './user-root/pending-bookings/pending-bookings.component'
import { UserRefferalsComponent } from './user-root/user-refferals/user-refferals.component'
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component'
import { TermsOfUseComponent } from './terms-of-use/terms-of-use.component'

const userRoutes: Routes = [
	{
		path: '',
		component: LoginComponent
	},
	{
		path: 'privacy',
		component: PrivacyPolicyComponent
	},
	{
		path: 'terms',
		component: TermsOfUseComponent
	},
	{
		path: 'login',
		component: LoginComponent
	},
	{
		path: 'register',
		component: RegisterComponent
	},
	{
		path: 'user',
		component: UserComponent,
		canActivate: [AuthGuard]
	},
	{
		path: 'user/account',
		component: AccountComponent,
		canActivate: [AuthGuard]

	},
	{
		path: 'user/refer',
		component: ReferComponent,
		canActivate: [AuthGuard]
	},
	{
		path: 'user/recruit/:id',
		component: RecruitDetailsComponent,
		canActivate: [AuthGuard]
	},
	{
		path: 'user/pending-bookings',
		component: PendingBookingsComponent,
		canActivate: [AuthGuard]
	},
	{
		path: 'user/referrals',
		component: UserRefferalsComponent,
		canActivate: [AuthGuard]
	}
]

@NgModule({
	imports: [
		RouterModule.forRoot(userRoutes)
	],
	exports: [
		RouterModule
	]
})

export class UserRootRoutingModule{}