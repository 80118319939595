<div class="app">
	<admin-header></admin-header>

	<div class="selected-salesman">
		<h1>{{ salesman?.first_name }} {{ salesman?.last_name }}</h1>
		<h4 *ngIf="salesman">{{ salesman?.email }}  :  {{ salesman?.primary_phone | phone }}</h4>
		<div class="address" *ngIf="salesman?.street && salesman?.city && salesman?.state && salesman?.zip">
			<p *ngIf="salesman?.street">{{ salesman?.street }}</p>
			<p *ngIf="salesman?.city">{{ salesman?.city + ', ' + salesman?.state + ' ' + salesman?.zip }}</p>
		</div>
	</div>

	<div class="container">
		

		<div class="controls">
			<div class="checks">
				<input id="1099" type="checkbox" name="1099" [checked]="salesman?.filled_tax_form" (change)="updateTaxFormStatus($event.target.checked)">
				<label for="1099">has 1099</label>
			</div>

			<form [formGroup]="paymentForm" (ngSubmit)="paySalesman()">
				<div class="field">
					<input type="number" formControlName="amount" placeholder="Amount">
				</div>

				<div class="field">
					<input type="text" formControlName="check_number" placeholder="Check Number">
				</div>
				
				<button class="btn btn-primary">Pay Salesman</button>
				<img *ngIf="loading" class="processing" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />

				<p class="form-error" *ngIf="f.amount.hasError('lessThan')">Amount can not be greater than the salesman's account total</p>
				<p *ngIf="submitted && !loading && success" class="form-success">Payment sent</p>
			</form>
		</div>

		<div>
			<div class="account-data">
				<div>
					<p>{{ salesman?.recruit_count }}</p>
					<p>{{ salesman?.recruit_count | i18nPlural: pluralMapping['salesman'] }} recruited</p>
				</div>

				<div>
					<p>{{ salesman?.account_total | currency:'USD':'symbol' }}</p>
					<p>Account total</p>
				</div>
			</div>
		</div>

		<div class="transaction-table" *ngIf="salesman?.transactions.length">
			<div class="transaction-header">
				<div class="transaction-date">Date</div>
				<div class="transaction-description">Description</div>
				<div class="transaction-run-total">Sale Total</div>
				<div class="transaction-amount">Amount</div>
				<div class="transaction-balance">Balance</div>
			</div>

			<div class="transactions">
				<div class="transaction" *ngFor="let transaction of salesman?.transactions">
					<div class="transaction-date">{{ transaction.createdAt | date }}</div>
					<div class="transaction-description">{{ transaction.description }}</div>
					<div class="transaction-run-total">{{ transaction.sale_amount  | currency:'USD':'symbol' }}</div>
					<div class="transaction-amount" [ngClass]="{'credit': transaction.amount > 0, 'debit': transaction.amount < 0}">{{ transaction.amount  | currency:'USD':'symbol' }}</div>
					<div class="transaction-balance">{{ transaction.balance | currency:'USD':'symbol' }}</div>
				</div>
			</div>
		</div>
	</div>
</div>