<div class="app">
	<user-header></user-header>

	<div class="container">
		<div *ngIf="!referrals?.length">
			<p>None of your referrals have booked a run</p>
		</div>

		<div class="referral-table" *ngIf="referrals?.length">

			<div class="referral-header">
				<div class="referral-name">Name</div>
				<div class="referral-phone">Reffered By</div>
				<div class="referral-booked">Date Booked</div>
				<div class="referral-booked">Possible Commission</div>
				<div class="referral-booked">Run End Date</div>
				<div class="referral-complete"></div>
			</div>

			<div class="referrals">
				<div class="referral" *ngFor="let referral of referrals">
					<div class="referral-name"><p>{{ referral.name }}</p></div>
					<div class="referral-phone"><p>{{ referral.user.first_name }} {{ referral.user.last_name }}</p></div>
					<div class="referral-booked"><p>{{ referral.booked_date | date }}</p></div>
					<div class="referral-booked"><p>{{ referral.total_amount * 0.05 | currency:'USD' }}</p></div>
					<div class="referral-booked"><p>{{ referral.run_end_date | date }}</p></div>
					<div class="referral-complete">
						<img *ngIf="referral.run_completed && !referral.cancelled" src="assets/icon-complete.png" srcset="assets/icon-complete@2x.png 2x" alt="run completed icon" title="Run completed">
						<img *ngIf="!referral.run_completed && !referral.cancelled" src="assets/icon-pending.png" srcset="assets/icon-pending@2x.png 2x" alt="run pending icon" title="Run pending">
						<img *ngIf="referral.cancelled" src="assets/icon-remove.png" srcset="assets/icon-remove@2x.png 2x" alt="run cancelled" title="Run cancelled">
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
